<template>
  <app-modal :title="title" :width="800" @submit="onSubmit">
    <v-alert
      v-if="displayAttendeesWarning"
      class="!bg-warning/20 mb-8"
      icon="ph:warning"
    >
      {{ data!.attendees.length }} attendees will be impacted by this session
      modification, ensure that you already warned them about it.

      <template #append>
        <v-btn
          color="warning"
          size="small"
          :to="{ name: 'sessions-sessionId', params: { sessionId: data!.id } }"
          variant="tonal"
        >
          View attendees
        </v-btn>
      </template>
    </v-alert>

    <div class="grid grid-cols-[70%_1fr] gap-4">
      <course-session-public-select
        v-model="form.public"
        data-cy-public
        :readonly="!!track"
      />

      <app-form-input-text
        v-model="form.capacity"
        data-cy-capacity
        label="Capacity"
        required
        type="number"
      />
    </div>

    <organization-autocomplete
      v-if="!form.public || form.organization_id !== undefined"
      v-model="form.organization_id"
      class="mb-6"
      :hint="
        track
          ? 'Organization has been automatically selected as the course you are referring to is part of an existing track.'
          : undefined
      "
      :readonly="!!track"
      required
    />

    <place-select v-model="form.place_id" data-cy-place />

    <p class="mb-4 flex items-center justify-between text-lg">
      Schedule

      <v-btn size="small" @click="onAddScheduleEntry"> Add entry </v-btn>
    </p>

    <div class="grid gap-6">
      <div v-for="(entry, index) in form.schedule" :key="index">
        <div class="flex items-center gap-4">
          <p class="text-base opacity-60">Day {{ index + 1 }}</p>

          <app-form-input-datetime-range
            v-model:end_date="form.schedule[index]!.end_date"
            v-model:start_date="form.schedule[index]!.start_date"
            class="flex-auto"
          />

          <v-btn
            :disabled="index === 0"
            icon="ph:trash"
            size="small"
            variant="flat"
            @click="onRemoveScheduleEntryByIndex(index)"
          />
        </div>
      </div>
    </div>

    <p class="mt-6 text-lg">Options</p>

    <div class="pl-4">
      <v-switch
        v-model="form.options.breakfast_included"
        color="primary"
        hide-details
        label="Breakfast included"
      />
      <v-switch
        v-model="form.options.lunch_included"
        color="primary"
        hide-details
        label="Lunch included"
      />
    </div>
  </app-modal>
</template>

<script lang="ts" setup>
import { cloneDeep } from "lodash";

import type { CourseSessionCreationRequest } from "~~/model/course-session.model";

const properties = defineProps<{
  sessionId?: DatabaseTable<"course_sessions">["id"];
  courseId: DatabaseTable<"courses">["id"];
}>();

const client = useDatabaseClient();

const [{ data }, { data: track }] = await Promise.all([
  useAsyncData(async () => {
    if (!properties.sessionId) return null;

    const { data } = await client
      .from("course_sessions")
      .select(
        `
      *,
      organization: organization_sessions(...organizations(*)),
      transactions(hubspot: hubspot_associations(*)),
      place: places(*),
      attendees: course_session_attendees(*, user: users(*))
      `,
      )
      .eq("id", properties.sessionId as number)
      .returns<
        Array<
          DatabaseTable<"course_sessions"> & {
            organization: DatabaseTable<"organizations"> | null;
            place: DatabaseTable<"places"> | null;
            attendees: Array<
              DatabaseTable<"course_session_attendees"> & {
                user: DatabaseTable<"users">;
              }
            >;
          }
        >
      >()
      .single();

    return data;
  }),
  useAsyncData(async () => {
    const { data } = await client
      .from("organization_track_courses")
      .select("...organization_tracks(*)")
      .eq("course_id", properties.courseId)
      .returns<DatabaseTable<"organization_tracks">[]>()
      .single();

    return data;
  }),
]);

const title = computed(() => (data ? "Edit session" : "Create a new session"));

const { close } = useModal("courseSessionEdition");

const form = reactive<CourseSessionCreationRequest>({
  public: data.value?.public ?? !track.value,
  capacity: data.value?.capacity ?? 12,
  place_id: data.value?.place_id as unknown as number,
  schedule: data.value?.schedule
    ? cloneDeep(data.value.schedule)
    : [
        {
          start_date: "",
          end_date: "",
        } as DatabaseCompositeType<"course_session_schedule_entry">,
      ],
  organization_id: data.value?.organization?.id ?? track.value?.organization_id,
  options: data.value?.options ?? {
    breakfast_included: false,
    lunch_included: false,
  },
});

const onAddScheduleEntry = () => {
  form.schedule.push({
    start_date: "",
    end_date: "",
  } as DatabaseCompositeType<"course_session_schedule_entry">);
};

const onRemoveScheduleEntryByIndex = (indexToBeRemoved: number) => {
  form.schedule = form.schedule.filter((_, index) => {
    return index !== indexToBeRemoved;
  });
};

watch(
  () => form.public,
  () => {
    form.organization_id = undefined;
  },
);

const onSubmit = () =>
  close({
    confirmed: true,
    payload: {
      ...form,
      // TODO : find a way to parse string as number for input of type number
      capacity: Number.parseInt(form.capacity as unknown as string, 10),
    },
  });

const displayAttendeesWarning = computed(() => {
  if (!data.value) return;

  const isPastSession = data.value.end_date! < new Date().toISOString();
  const numberOfAttendees = data.value.attendees.length;

  return !(isPastSession || numberOfAttendees === 0);
});
</script>
